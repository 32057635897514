import type { TeamUpView } from './models';

export const generateTUIframe = (
  authToken: string,
  view: TeamUpView,
  locale: string,
  providerId: string,
  specificPages: string[],
  identityProvider: string,
) => {
  return document.createTextNode(
    `teamup.calendar({\n    provider_id: ${providerId},\n    type: "specific_pages",\n    target_element_id: "teamup",\n    venue_ids: "~all",\n    width: "100%",\n    specific_pages: ["${specificPages.toString()}"],\n    iframe_classes: ['data-ot-ignore'],\n    view: "${view}",\n    identity_provider: "${identityProvider}",\n    locale: "${locale}",\n    auth0_id_token: "${authToken}",\n    disable_venue_selector: false,\n    height: "fit_viewport",\n    version: 2})
    ;`,
  );
};
