/* eslint-disable */
/** GENERATED FILE. Do not edit. To regenerate: yarn run write-app-config-source **/

export const ADA_BOT_HANDLE = process.env.ADA_BOT_HANDLE;
export const ADA_BOT_HANDLE_GENERATIVE = process.env.ADA_BOT_HANDLE_GENERATIVE;
export const AFFIRM_PUBLIC_KEY_AU = process.env.AFFIRM_PUBLIC_KEY_AU;
export const AFFIRM_PUBLIC_KEY_CA = process.env.AFFIRM_PUBLIC_KEY_CA;
export const AFFIRM_PUBLIC_KEY_US = process.env.AFFIRM_PUBLIC_KEY_US;
export const AFFIRM_SCRIPT_URL_AU = process.env.AFFIRM_SCRIPT_URL_AU;
export const AFFIRM_SCRIPT_URL_CA = process.env.AFFIRM_SCRIPT_URL_CA;
export const AFFIRM_SCRIPT_URL_US = process.env.AFFIRM_SCRIPT_URL_US;
export const AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_CA = process.env.AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_CA;
export const AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_US = process.env.AFFIRM_TRANSACTIONS_API_PUBLIC_KEY_US;
export const ALLOW_COPY_XRAY = process.env.ALLOW_COPY_XRAY?.toString() === 'true';
export const ALLOW_DATE_TRIGGER_PANEL = process.env.ALLOW_DATE_TRIGGER_PANEL?.toString() === 'true';
export const ALLOW_FEATURE_TOGGLES = process.env.ALLOW_FEATURE_TOGGLES?.toString() === 'true';
export const ALLOW_GRAPHQL_DEV_TOOLS = process.env.ALLOW_GRAPHQL_DEV_TOOLS?.toString() === 'true';
export const ALLOW_MWI_PREVIEW_INSTRUCTOR_PAGES = process.env.ALLOW_MWI_PREVIEW_INSTRUCTOR_PAGES?.toString() === 'true';
export const ALLOW_PREVIEW_COPY = process.env.ALLOW_PREVIEW_COPY?.toString() === 'true';
export const ALLOW_PROMO_PANEL = process.env.ALLOW_PROMO_PANEL?.toString() === 'true';
export const ALLOW_QUERY_TOGGLES = process.env.ALLOW_QUERY_TOGGLES?.toString() === 'true';
export const ALLOW_SANDBOX_ROUTES = process.env.ALLOW_SANDBOX_ROUTES?.toString() === 'true';
export const ALLOW_TOGGLE_PANEL = process.env.ALLOW_TOGGLE_PANEL?.toString() === 'true';
export const APP = process.env.APP;
export const APPLITOOLS_API_KEY = process.env.APPLITOOLS_API_KEY;
export const APP_STATE_CONFIG = process.env.APP_STATE_CONFIG;
export const BUILD_TIME_GRAPHQL_URI = process.env.BUILD_TIME_GRAPHQL_URI;
export const CART_CHECKOUT_URL = process.env.CART_CHECKOUT_URL;
export const CITIZENS_WIDGET_ID = process.env.CITIZENS_WIDGET_ID;
export const CITIZENS_WIDGET_SDK = process.env.CITIZENS_WIDGET_SDK;
export const CLASS_PAGE_LIMIT = process.env.CLASS_PAGE_LIMIT;
export const CLOUDINARY_ACCOUNT = process.env.CLOUDINARY_ACCOUNT;
export const COMMERCETOOLS_API_URL = process.env.COMMERCETOOLS_API_URL;
export const COMMERCETOOLS_APPAREL_CATEGORY_ID = process.env.COMMERCETOOLS_APPAREL_CATEGORY_ID;
export const COMMERCETOOLS_AUTH_URL = process.env.COMMERCETOOLS_AUTH_URL;
export const COMMERCETOOLS_CLIENT_ID = process.env.COMMERCETOOLS_CLIENT_ID;
export const COMMERCETOOLS_CLIENT_ID_PROD = process.env.COMMERCETOOLS_CLIENT_ID_PROD;
export const COMMERCETOOLS_CLIENT_SECRET = process.env.COMMERCETOOLS_CLIENT_SECRET;
export const COMMERCETOOLS_CLIENT_SECRET_PROD = process.env.COMMERCETOOLS_CLIENT_SECRET_PROD;
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_AU = process.env.COMMERCETOOLS_INVENTORY_CHANNEL_ID_AU;
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_CA = process.env.COMMERCETOOLS_INVENTORY_CHANNEL_ID_CA;
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_DE = process.env.COMMERCETOOLS_INVENTORY_CHANNEL_ID_DE;
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_GB = process.env.COMMERCETOOLS_INVENTORY_CHANNEL_ID_GB;
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_US = process.env.COMMERCETOOLS_INVENTORY_CHANNEL_ID_US;
export const COMMERCETOOLS_MARKDOWN_CHANNEL_ID = process.env.COMMERCETOOLS_MARKDOWN_CHANNEL_ID;
export const COMMERCETOOLS_PROJECT_KEY = process.env.COMMERCETOOLS_PROJECT_KEY;
export const COMMERCETOOLS_PROJECT_KEY_PROD = process.env.COMMERCETOOLS_PROJECT_KEY_PROD;
export const COMMERCETOOLS_REGION = process.env.COMMERCETOOLS_REGION;
export const COMMERCETOOLS_REGION_PROD = process.env.COMMERCETOOLS_REGION_PROD;
export const COMMERCETOOLS_WEB_CHANNEL_ID = process.env.COMMERCETOOLS_WEB_CHANNEL_ID;
export const COMMIT_REF = process.env.COMMIT_REF;
export const CONTENTFUL_WWW_PREVIEW_TOKEN = process.env.CONTENTFUL_WWW_PREVIEW_TOKEN;
export const CONTENTFUL_WWW_PROD_TOKEN = process.env.CONTENTFUL_WWW_PROD_TOKEN;
export const CRA_WWW_FALLBACK_BASE_URL = process.env.CRA_WWW_FALLBACK_BASE_URL;
export const DD_RUM_SAMPLE_RATE = process.env.DD_RUM_SAMPLE_RATE;
export const DRIFT_KEY = process.env.DRIFT_KEY;
export const ENABLE_NEBULA_CF_GLOBAL_ENTRIES_ENDPOINTS = process.env.ENABLE_NEBULA_CF_GLOBAL_ENTRIES_ENDPOINTS?.toString() === 'true';
export const ENABLE_NEBULA_CT_PRICING_ENDPOINTS = process.env.ENABLE_NEBULA_CT_PRICING_ENDPOINTS?.toString() === 'true';
export const EXCLUDE_LARGE_SLUGS = process.env.EXCLUDE_LARGE_SLUGS;
export const GOOGLE_GEOLOCATION_API_KEY = process.env.GOOGLE_GEOLOCATION_API_KEY;
export const GRAPHQL_ENDPOINT = process.env.GRAPHQL_ENDPOINT;
export const GRAPHQL_HOST = process.env.GRAPHQL_HOST;
export const GRAPHQL_PROD_HOST = process.env.GRAPHQL_PROD_HOST;
export const HIDE_CONSOLE_LOGS = process.env.HIDE_CONSOLE_LOGS?.toString() === 'true';
export const I18N_LOCALE_TOGGLE = process.env.I18N_LOCALE_TOGGLE?.toString() === 'true';
export const I18N_NO_BASEPATH_IN_REDIRECTS = process.env.I18N_NO_BASEPATH_IN_REDIRECTS;
export const IDME_CLIENT_ID = process.env.IDME_CLIENT_ID;
export const IS_NEXT = process.env.IS_NEXT?.toString() === 'true';
export const IS_VERCEL_DEPLOY = process.env.IS_VERCEL_DEPLOY;
export const MONITORING_ENV = process.env.MONITORING_ENV;
export const MWYIRESULTS_ACCOUNT_PASSWORD = process.env.MWYIRESULTS_ACCOUNT_PASSWORD;
export const MWYIRESULTS_ACCOUNT_USERNAME = process.env.MWYIRESULTS_ACCOUNT_USERNAME;
export const MWYIRESULTS_REST_API_BASEURL = process.env.MWYIRESULTS_REST_API_BASEURL;
export const NEBULA_CORS_DOMAIN = process.env.NEBULA_CORS_DOMAIN;
export const NEBULA_ENDPOINT = process.env.NEBULA_ENDPOINT;
export const NEXT_PUBLIC_HOSTNAME = process.env.NEXT_PUBLIC_HOSTNAME;
export const NO_INDEX = process.env.NO_INDEX?.toString() === 'true';
export const ONEPELOTON_API_URL = process.env.ONEPELOTON_API_URL;
export const ONEWELLNESS_OAUTH_CLIENT_ID = process.env.ONEWELLNESS_OAUTH_CLIENT_ID;
export const ONEWELLNESS_OAUTH_DOMAIN = process.env.ONEWELLNESS_OAUTH_DOMAIN;
export const ONEWELLNESS_PROD_BENEFIT_AUTH_TOKEN = process.env.ONEWELLNESS_PROD_BENEFIT_AUTH_TOKEN;
export const ONEWELLNESS_UAT_BENEFIT_AUTH_TOKEN = process.env.ONEWELLNESS_UAT_BENEFIT_AUTH_TOKEN;
export const ONEWELLNESS_UAT_CF_ACCESS_CLIENT_ID = process.env.ONEWELLNESS_UAT_CF_ACCESS_CLIENT_ID;
export const ONEWELLNESS_UAT_CF_ACCESS_CLIENT_SECRET = process.env.ONEWELLNESS_UAT_CF_ACCESS_CLIENT_SECRET;
export const ONE_TRUST_KEY_AT = process.env.ONE_TRUST_KEY_AT;
export const ONE_TRUST_KEY_AU = process.env.ONE_TRUST_KEY_AU;
export const ONE_TRUST_KEY_CA = process.env.ONE_TRUST_KEY_CA;
export const ONE_TRUST_KEY_DE = process.env.ONE_TRUST_KEY_DE;
export const ONE_TRUST_KEY_GB = process.env.ONE_TRUST_KEY_GB;
export const ONE_TRUST_KEY_US = process.env.ONE_TRUST_KEY_US;
export const OPTIMIZELY_DATAFILE = process.env.OPTIMIZELY_DATAFILE;
export const OPTIMIZELY_FULLSTACK_SDK_KEY = process.env.OPTIMIZELY_FULLSTACK_SDK_KEY;
export const OPTIMIZELY_KEY = process.env.OPTIMIZELY_KEY;
export const PAYMENT_PLATFORM_API_URL = process.env.PAYMENT_PLATFORM_API_URL;
export const PB_CF_CMA_TOKEN = process.env.PB_CF_CMA_TOKEN;
export const PB_CF_DELIVERY_ACCESS_TOKEN = process.env.PB_CF_DELIVERY_ACCESS_TOKEN;
export const PB_CF_ENV = process.env.PB_CF_ENV;
export const PB_CF_PREVIEW_ACCESS_TOKEN = process.env.PB_CF_PREVIEW_ACCESS_TOKEN;
export const PB_CF_SPACE_ID = process.env.PB_CF_SPACE_ID;
export const PELOFAX_API_BASE_URL = process.env.PELOFAX_API_BASE_URL;
export const PREVIEW_NEBULA_ENDPOINT = process.env.PREVIEW_NEBULA_ENDPOINT;
export const PREVIEW_SLUGS = process.env.PREVIEW_SLUGS;
export const PREVIEW_VERCEL_NEBULA_ENDPOINT = process.env.PREVIEW_VERCEL_NEBULA_ENDPOINT;
export const PRODUCTION = process.env.PRODUCTION;
export const PROSPECTS_API_ENABLED = process.env.PROSPECTS_API_ENABLED?.toString() === 'true';
export const PROSPECTS_API_TOKEN = process.env.PROSPECTS_API_TOKEN;
export const PROSPECTS_FILTERS_API_DOMAIN = process.env.PROSPECTS_FILTERS_API_DOMAIN;
export const REACT_APP_API_ENV = process.env.REACT_APP_API_ENV;
export const REACT_APP_PRIVATE_ENV = process.env.REACT_APP_PRIVATE_ENV;
export const RECAPTCHA_ENTERPRISE_KEY = process.env.RECAPTCHA_ENTERPRISE_KEY;
export const RELOAD_NEXT_ROUTES = process.env.RELOAD_NEXT_ROUTES?.toString() === 'true';
export const REVALIDATION_ENABLED = process.env.REVALIDATION_ENABLED?.toString() === 'true';
export const SEGMENT_KEY = process.env.SEGMENT_KEY;
export const SEGMENT_KEY_AT = process.env.SEGMENT_KEY_AT;
export const SEGMENT_KEY_AU = process.env.SEGMENT_KEY_AU;
export const SEGMENT_KEY_CA = process.env.SEGMENT_KEY_CA;
export const SEGMENT_KEY_DE = process.env.SEGMENT_KEY_DE;
export const SEGMENT_KEY_GB = process.env.SEGMENT_KEY_GB;
export const SEGMENT_KEY_US = process.env.SEGMENT_KEY_US;
export const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN;
export const SHOW_PB_COMPONENT_ERRORS = process.env.SHOW_PB_COMPONENT_ERRORS?.toString() === 'true';
export const STRIPE_ACCOUNT_KEY_AT = process.env.STRIPE_ACCOUNT_KEY_AT;
export const STRIPE_ACCOUNT_KEY_AU = process.env.STRIPE_ACCOUNT_KEY_AU;
export const STRIPE_ACCOUNT_KEY_CA = process.env.STRIPE_ACCOUNT_KEY_CA;
export const STRIPE_ACCOUNT_KEY_DE = process.env.STRIPE_ACCOUNT_KEY_DE;
export const STRIPE_ACCOUNT_KEY_GB = process.env.STRIPE_ACCOUNT_KEY_GB;
export const STRIPE_API_KEY = process.env.STRIPE_API_KEY;
export const TEAM_API_BASE_URL = process.env.TEAM_API_BASE_URL;
export const TLD = process.env.TLD;
export const USE_APOLLO_V3 = process.env.USE_APOLLO_V3?.toString() === 'true';
export const USE_APOLLO_V3_ACCOUNT = process.env.USE_APOLLO_V3_ACCOUNT?.toString() === 'true';
export const USE_APOLLO_V3_ECOPY = process.env.USE_APOLLO_V3_ECOPY?.toString() === 'true';
export const USE_NEXT_LINK = process.env.USE_NEXT_LINK?.toString() === 'true';
export const USE_RUNTIME_BUILD_FALLBACK = process.env.USE_RUNTIME_BUILD_FALLBACK?.toString() === 'true';
export const VERCEL_NEBULA_ENDPOINT = process.env.VERCEL_NEBULA_ENDPOINT;